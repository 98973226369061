/* eslint-disable prettier/prettier */
// noinspection JSUnusedGlobalSymbols

import { NativeModules, Platform } from 'react-native';

const LINKING_ERROR =
  `The package 'react-native-firebase-refresh-token' doesn't seem to be linked. Make sure: \n\n` +
  Platform.select({ ios: "- You have run 'pod install'\n", default: '' }) +
  '- You rebuilt the app after installing the package\n' +
  '- You are not using Expo managed workflow\n';

const FirebaseRefreshToken = NativeModules.FirebaseRefreshToken
  ? NativeModules.FirebaseRefreshToken
  : new Proxy(
      {},
      {
        get() {
          throw new Error(LINKING_ERROR);
        },
      }
    );

export const getRefreshTokenAndroid = async (fileName: string) => {
  if (Platform.OS === 'android') {
    return await FirebaseRefreshToken.getRefreshTokenAndroid(fileName);
  } else {
    return Promise.reject('Android only');
  }
};

export const getInitialURLAndroid = async () => {
  if (Platform.OS === 'android') {
    return await FirebaseRefreshToken.getInitialURL();
  } else {
    return Promise.reject('Android only');
  }
};
